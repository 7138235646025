/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Wrapper from '../components/Wrapper'

const pricingData = [
  {
    giftAmount: 25,
    adminFee: 4.95,
  },
  {
    giftAmount: 50,
    adminFee: 7.95,
  },
  {
    giftAmount: 100,
    adminFee: 9.95,
  },
]

const PricingPage = () => (
  <Layout>
    <SEO title="Pricing" />
    <Section title="Pricing">
      <Wrapper>
        <div
          sx={{
            overflowX: "auto",
            maxWidth: "420px",
            mx: "auto",
          }}
        >
          <table
            sx={{
              variant: "tables.striped",
              boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
            }}
          >
            <thead>
              <tr>
                <th>Gift Amount</th>
                <th>Admin Fee</th>
              </tr>
            </thead>
            <tbody>
              {pricingData.map((item, index) => (
                <tr key={index}>
                  <td>${item.giftAmount}</td>
                  <td>${item.adminFee}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div sx={{ mt: 3 }}>
            * Admin fees will be deducted from each graduation gift
          </div>
        </div>
      </Wrapper>
    </Section>
  </Layout>
)

export default PricingPage
